import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import reject from 'lodash/reject';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import join from 'lodash/join';
import window from 'window-or-global';
import reactCookie from 'react-cookie';
import Auth from '@aws-amplify/auth';
import { getAuthApiData } from 'client-utils/amplifyUtils';
import {
  getGuestTokens
} from '@nmg/auth';
import axios from 'axios';
import { BR_SEGMENTS, X_ABTEST_INFO, X_DEVICE_TYPE, ABTEST_OPT_ANALYTICS  } from 'universal/custom-http-headers';
import httpWithLogging from 'universal/http-client';
import logger from 'server/utilities/logger';
import { stringify } from 'query-string';
import { buildCookieString } from 'universal/utilities-cookies';
import { replaceSpaceWithHyphenAndEncode, getParams, formatDesignerName } from 'client-utils/utilities-router';
import { seoSelectedFilter } from 'client-utils/utilities-seoFacetUtag';
import { swatchesMapper } from 'client-utils/swatchesMapper';
import {
  MY_FAVORITES,
  FAVORITES_NOT_AVAILABLE_MESSAGE,
  IN_STORE,
  PRODUCT_LIST_FILTER_OPTIONS_EMPTY_MESSAGE,
  INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE,
  FAST_DELIVERY,
  FREE_PICKUP,
  PAGE_SIZE_60
} from 'plp/constants';
import {
  SET_CANONICAL_URL,
  getABTestAssignments,
  getABTests,
  SET_TEST_FROM_COOKIE,
  ABT_SALE_FACET,
  ABT_FACET_ORDER,
  ABTEST_GET_IT_FAST,
  AB_TEST_VERTEX_AI
} from 'shared/actions/actions-page';
import { types as NavTypes } from 'storefront/components/LeftNavigation/actions-leftnavigation';
import { setRecentlySearchedAnalytics } from 'client/storefront/components/Header/Search/search-actions';
import { showSpinner, hideSpinner } from 'shared/components/Spinner/spinner-actions';
import { openModal } from 'shared/components/Modal/actions';
import { LOADING_PRODUCT } from 'pdp/pages/ProductPage/actions';
import { isEmptyInStoreFilterValue } from 'plpFilters/components/InStoreFilter/inStoreFilter';
import { getSelectedGender } from 'client-utils/utilities-gender';
import { getSelectedFilterCount } from './components/ProductList/components/Filters/filters';
import { BR_SEG_COOKIE_NAME, FAVORITE_DESIGNERS } from "storefront/components/constants"
import { updateGrsUtagData } from 'client/middleware/analytics/utagData';

export const LOADING_PRODUCT_LIST = 'LOADING_PRODUCT_LIST';
export const LOADING_PRODUCT_LIST_METADATA = 'LOADING_PRODUCT_LIST_METADATA';
export const RESOLVED_PRODUCT_LIST = 'RESOLVED_PRODUCT_LIST';
export const REJECTED_PRODUCT_LIST = 'REJECTED_PRODUCT_LIST';
export const RESOLVED_PRODUCT_LIST_METADATA = 'RESOLVED_PRODUCT_LIST_METADATA';
export const RESOLVED_FAVORITE = 'RESOLVED_FAVORITE';
export const RESOLVED_FAVORITE_FLATTENED = 'RESOLVED_FAVORITE_FLATTENED';
export const RESOLVED_VISUALNAV = 'RESOLVED_VISUALNAV';

export const RESOLVED_CATEGORY_NO_RESULTS_CONTENT = 'RESOLVED_CATEGORY_NO_RESULTS_CONTENT';
export const REJECTED_CATEGORY_NO_RESULTS_CONTENT = 'REJECTED_CATEGORY_NO_RESULTS_CONTENT';
export const SET_PRODUCT_LIST_DATA_TO_UTAG = 'SET_PRODUCT_LIST_DATA_TO_UTAG';
export const ADD_RECENT_SIZES_TO_UTAG_DATA = 'ADD_RECENT_SIZES_TO_UTAG_DATA';
export const SET_FAVORITE_UTAG_DATA = 'SET_FAVORITE_UTAG_DATA';
export const INVALID_CATEGORY = 'INVALID_CATEGORY';
export const VALID_CATEGORY = 'VALID_CATEGORY';
export const SET_ALT_IMAGE_UTAG = 'SET_ALT_IMAGE_UTAG';
export const SET_MVP_FILTER_UTAG = 'SET_MVP_FILTER_UTAG';
export const REMOVE_MVP_FILTER_UTAG = 'REMOVE_MVP_FILTER_UTAG';

export const SOURCE_QUICK_LOOK = 'SOURCE_QUICK_LOOK';
export const RESET_QUICK_LOOK_PRODUCT = 'RESET_QUICK_LOOK_PRODUCT';
export const RESOLVED_QUICK_LOOK_PRODUCT = 'RESOLVED_QUICK_LOOK_PRODUCT';
export const NO_RESULTS_FOR_FILTER = 'NO_RESULTS_FOR_FILTER';

export const NO_RESULTS_FOR_FACET_FILTER = 'NO_RESULTS_FOR_FACET_FILTER';
export const INIT_SWATCH_VALUES = 'INIT_SWATCH_VALUES';
export const UPDATE_SWATCH_VALUE = 'UPDATE_SWATCH_VALUE';

export const UPDATE_CAT_ID = 'UPDATE_CAT_ID';

export const SET_SEO_FACETS_ORDER = 'SET_SEO_FACETS_ORDER';
export const SET_SEO_FACETS_MAP = 'SET_SEO_FACETS_MAP';
export const IN_STORE_FACET_AVAILABLE = 'IN_STORE_FACET_AVAILABLE';

export const types = {
  SET_CATEGORY: 'SET_CATEGORY',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
  SELECT_FILTER_OPTION: 'SELECT_FILTER_OPTION'
};

export const breadcrumbsUtag = (breadcrumbs) => (isEmpty(breadcrumbs) ? null : {
  breadCrumb: map(breadcrumbs, 'name'),
  catId: map(breadcrumbs, 'id'),
});

export const formatAnalyticsPageName = (templateType, data) => {
  if (templateType === 'emag') {
    return 'Emag';
  }
  if (templateType === 'F') {
    return 'F0';
  }
  if (templateType === 'DesignerIndex') {
    data.push('Designers');
    return data.map((curr) => curr).join(':');
  }
  return data.map((curr) => curr.name).join(':');
};

export function addRecentSizesToUtagData(recentSizes) {
  return (dispatch) => {
    dispatch({ type: ADD_RECENT_SIZES_TO_UTAG_DATA, payload: { recentSizes } });
  };
}

export function getPlpCategory(requestOptions = {}) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(getProductList(requestOptions))
      .then(resolve)
      .catch(() => {
        dispatch({ type: REJECTED_PRODUCT_LIST });
        dispatch({ type: SET_CANONICAL_URL, canonicalUrl: '' });
        reject();
      });
  });
}

function getRecentlyViewedFromStorage() {
  let recentlyViewed;
  try {
    const RECENTLY_VIEWED_KEY = 'nm_recently_viewed';
    const storageValue = window.localStorage?.getItem(RECENTLY_VIEWED_KEY);
    if (!isEmpty(storageValue)) {
      recentlyViewed = JSON.parse();
    }
  } catch(err){}
  return recentlyViewed;
}

export function fetchRecentlyViewedProductsData() {
  return (dispatch, getState) => {
    const state = getState();

    const { session = '' } = state;
    
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    
    const requestApi = httpWithLogging(state);

    const queryStringProductIds = getRecentlyViewedFromStorage();
    

    let requestURI = `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP_COMPOSITE}?productIds=${queryStringProductIds}`


    return requestApi.get(requestURI, { headers })
      .then((response) => {
      
        
        const minifiedProductInfo = get(
          response.data,
          'minifiedProductInfo',
          [],
        );
  
        if (!minifiedProductInfo.length) {
          dispatch({ type: 'REJECTED_NM_RECENTLY_VIEWED_PRODUCTS' });
        }
  
        const recentlyViewedProducts = minifiedProductInfo
          .filter((item) => item?.displayable && item.displayable)
          .filter(
            (item) =>
              item?.media?.main?.medium?.url || item?.media?.main?.dynamic?.url,
          ) // remove any products without images
          .map((item) => {
            const newItem = {
              id: item.id,
              title: item.title,
              image:
                item?.media?.main?.medium?.url ||
                item?.media?.main?.dynamic?.url ||
                '',
              href: item.canonicalUrl,
            };
  
            return { ...newItem };
          });

          
        dispatch({
          type: 'RESOLVED_NM_RECENTLY_VIEWED_PRODUCTS',
          payload: recentlyViewedProducts,
        });
       

      })
      .catch((error) => {
        logger.error('MinifiedProductCall error for recently viewed', error);
        dispatch({ type: 'REJECTED_NM_RECENTLY_VIEWED_PRODUCTS' });
      });
  };
}


export function getPlpCategoryWithSpecificRecs(requestOptions = {}, MBOX_IDS) {
  return (dispatch) => dispatch(getABTestAssignments(MBOX_IDS.join(','), true))
    .then(() => {
      dispatch(getPlpCategory(requestOptions));
    })
    .catch(() => {
      dispatch(getPlpCategory(requestOptions));
    });
}

export function getPlpCategoryWithTests(requestOptions = {}, TEST_IDS) {
  return (dispatch, getState) => {
    const testIdsForService = [];
    const state = getState();
    for (let i = 0; i < TEST_IDS.length; i++) {
      if (state.cookies[TEST_IDS[i].abTestCookieName]) {
        dispatch({
          type: SET_TEST_FROM_COOKIE,
          payload: {
            ...TEST_IDS[i],
            abTestCookieValue: state.cookies[TEST_IDS[i].abTestCookieName],
            abTestExperience: '',
          },
        });
      } else {
        testIdsForService.push(TEST_IDS[i]);
      }
    }
    if (testIdsForService.length > 0) {
      return dispatch(getABTests(testIdsForService))
        .then(() => {
          dispatch(getPlpCategory(requestOptions));
        })
        .catch(() => {
          dispatch(getPlpCategory(requestOptions));
        });
    }
    return dispatch(getPlpCategory(requestOptions));
  };
}

export function getPlpOptions(requestOptions = {}, location = {}) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(getProductList(requestOptions, location))
      .then(resolve)
      .catch((err) => {
        if (err.type === NO_RESULTS_FOR_FILTER) {
          dispatch(openModal({
            type: 'ModalWithoutHeader',
            message: err.message,
            className: 'modal-without-header',
          }));
        } else if (err.type === NO_RESULTS_FOR_FACET_FILTER) {
          dispatch(hideSpinner());
          dispatch(openModal({
            type: 'ModalWithoutHeader',
            message: err.message,
            className: 'modal-without-header',
          }));
        } else if (!err.isHandled) {
          dispatch(openModal({ type: 'ProductListErrorModal' }));
        }
        reject();
      });
  });
}

export function getUtagFiltersData(filterOptions, state) {
  const inStoreFilter = 'In Store';
  const getItFastSplitFacetToggle= get(state, `abTestsOpt.${ABTEST_GET_IT_FAST}.variation`, 'a') === 'b';
  
  const level3Filter = find((get(state, `productListPage.products.applicableFilters`, [])), { filterKey: "level3" });
  const bucket2Filter = find((get(state, `productListPage.products.applicableFilters`, [])), { filterKey: "Bucket 2" });
  const level3FilterDisplayText = get(level3Filter, 'displayText', null);
  const bucket2FilterDisplayText = get(bucket2Filter, 'displayText', null);

  const renameKeyForUtagFilterAnalysis = (key, omniArray) => {
    switch (key) {
      case 'level1':
        return 'Category';
      case 'level2':
        return 'Type';
      case 'level3':
        return level3FilterDisplayText || 'Subcategory';
      case 'Bucket 2':
        return bucket2FilterDisplayText || 'Subcategory';
      case 'level4':
        return 'Subtype';
      case 'level5':
        return 'Product Type';
      case 'In Store':
        if (getItFastSplitFacetToggle) {
          if (!isEmpty(omniArray[2]) || !isEmpty(omniArray[3])) {
            return FAST_DELIVERY;
          }
          if (!isEmpty(omniArray[1])) {
            return FREE_PICKUP;
          }
          return '';
        }
        return 'Get It Fast';
      default:
        return key;
    }
  };

  const filterOmniSelection = (omniArray) => {
    const sddSelection = getItFastSplitFacetToggle ? "Today" : 'delivery today';
    const nddSelection = getItFastSplitFacetToggle ? "Tomorrow" : 'delivery tomorrow';
    const cspSelection = getItFastSplitFacetToggle ? "Today" : 'curbside today';

    if (!isEmpty(omniArray[2]) && !isEmpty(omniArray[3])) {
      return [sddSelection, nddSelection];
    } if (!isEmpty(omniArray[2])) {
      return [sddSelection];
    } if (!isEmpty(omniArray[3])) {
      return [nddSelection];
    } if (!isEmpty(omniArray[1])) {
      return [cspSelection];
    }
    return [];
  };

  const filterOptionsWithoutInstoreZipCode = {
    ...filterOptions,
    [inStoreFilter]: filterOmniSelection(get(filterOptions, inStoreFilter, [])),
  };

  return {
    filterSelection: flatten(
      reject(filterOptionsWithoutInstoreZipCode, isEmpty)
    ),
    filterType: flatMap(
      Object.keys(filterOptionsWithoutInstoreZipCode),
      (key) => Array(
        filterOptionsWithoutInstoreZipCode[key].length
      ).fill(renameKeyForUtagFilterAnalysis(key, get(filterOptions, inStoreFilter, [])))
    ),
  };
}

export function getProductList(requestOptions = {}, location = {}) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(showSpinner());
    dispatch({ type: LOADING_PRODUCT_LIST });

    const currentCategory = get(state, 'productListPage.currentCategory');
    const currentPage = get(state, 'productListPage.products.currentPage', 1);
    const selectedZipCodeCookie = reactCookie.load('dt_selected_zipcode') && reactCookie.load('dt_selected_zipcode').slice(1, -1);
    const {
      page = currentPage,
      categoryId = get(currentCategory, 'id', '').split('_')[0],
      parentCategoryId = '',
      siloCategoryId,
      sortBy = '',
      filterOptions,
      priorityProdId,
    } = requestOptions;
    const { user, session } = state;
    const brSegmentsToggle = get(state, 'toggles.RTCSD_PLP_SRP', false);
    const brand = state.brand_name?.env;
    const facetOrderToggle = get(state, 'toggles.ABTEST_FACET_ORDER_PLP', false);
    const facetOrderABTestVariation = get(state, `abTestsOpt.${ABT_FACET_ORDER}.variation`,false);
    const facetOrderABTestAnalytics = get(state, `abTestsOpt.${ABT_FACET_ORDER}.analytics`,'');
    const abtTestOptArr = getOptAnalyticsFromState(state);
    const optAnalytic = get(session, '_optanalytics', '');
    const optAnalyticsResult = abtTestOptArr.length > 0 ?  abtTestOptArr : optAnalytic.split(",");
    if (facetOrderToggle && facetOrderABTestVariation === 'b' && brand !== 'HC') {
     if(!optAnalyticsResult.includes(facetOrderABTestAnalytics)){
      optAnalyticsResult.push(facetOrderABTestAnalytics);
     }
    }
    const brSegments = get(session, BR_SEG_COOKIE_NAME);
    const context = state.api && state.api.requestContext;
    let deviceType;
    if (get(session, 'deviceType.isMobile', false) && !get(session, 'deviceType.isTablet', false)) {
      deviceType = 'M';
    } else if (get(session, 'deviceType.isTablet', false)) {
      deviceType = 'T';
    } else {
      deviceType = 'D';
    }  
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
        TLTSID: get(session, 'TLTSID', ''),
        ENABLE_STUB_SERVICE: get(state, 'cookies.enableStubService'),
        ENABLE_PROMO_PREVIEW: get(state, 'cookies.enablePromoPreview'),
        PLP_ONLY_X_LEFT: get(state.toggles, 'PLP_ONLY_X_LEFT', false),
        WID: get(state, 'utag.userSession.web_id', ''),
        ucid: get(user, 'ucid', ''),
      }),
      [X_ABTEST_INFO]: join(get(state, 'abTests.responses', []).map((i) => `${i.mboxId}:${get(i, 'assignment.value.experienceId', '')}`), '~'),
      [X_DEVICE_TYPE]: deviceType,
      ucid: get(user, 'ucid', ''),
      wid: get(state, 'utag.userSession.web_id', ''),
      pageurl: get(session, 'url', ''),
      br_uid: get(context, '_br_uid_2', ''),
      abTestsOpt: optAnalyticsResult.join(","),
    };
    if( brSegmentsToggle && brSegments ){
      headers[BR_SEGMENTS] = brSegments;
    }
    headers.zip = selectedZipCodeCookie !== undefined
      ? selectedZipCodeCookie
      : get(context, 'akamaiEdgescape.zip', '');
    const seoToggle = get(state, 'toggles.SEO_BUCKET_ONE', false);  
    if (seoToggle) {
      headers['seo_query'] = get(location, 'search', '');
    }

    const vertexToggle = get(state, 'toggles.VERTEX_AI', false) || (get(state, 'toggles.AB_TEST_VERTEX_AI', false) && get(state, `abTestsOpt.${AB_TEST_VERTEX_AI}.variation`, 'a') === 'b');
    if(vertexToggle){
      const breadcrumbs = get(state, 'navigation.breadcrumbs', []);
      let breadcrumbString = '';
      if(breadcrumbs && breadcrumbs.length > 0){
        breadcrumbString = breadcrumbs.map(crumb => crumb.name).join(">");
      }
      const cstmr = reactCookie.load('cstmr');
      headers['breadcrumbpath'] = breadcrumbString;
      headers['visitorid'] = cstmr?.customerId || '';
    }

    if (get(session, '_optuid')) headers.optuid = get(session, '_optuid');
    const requestApi = httpWithLogging(state, get(state, 'apiTimeouts.PRODUCT_LIST_API_TIMEOUT', 8000), false, 'productlisting');
    const isPLP60ToggleEnabled = get(state, 'toggles.AB_TEST_PLP_60', false) && get(state, `abTestsOpt.tl357.variation`, 'a') === 'b';

    function getProductListApiURL(
      categoryId,
      parentCategoryId,
      siloCategoryId,
      page,
      sortBy,
      filterOptions,
      personalizedPromos,
      priorityProdId
    ) {
      const sortByParams = sortBy ? `&sortBy=${sortBy}` : '';
      const personalizedPromosParams = personalizedPromos && personalizedPromos.length ? `&personalizedPromos=${personalizedPromos}` : '';
      const priorityProdIdParam = priorityProdId && priorityProdId.length ? `&priorityProdId=${priorityProdId}` : '';
      const tabsInSizeFacet = get(state, 'facetedLeftNav.tabsInSizeFacet', false);

      const defaultParams = {
        categoryId,
        parentCategoryId,
        siloCategoryId,
        page,
        filterOptions,
      };

      const queryBuilder = (fetchSize, tabsInSizeFacet) => {
        let params = {};

        if (fetchSize !== null || false) {
          params = { ...params, fetchSize };
        }

        if (tabsInSizeFacet) {
          params = { ...params, tabsInSizeFacet };
        }

        return params;
      };
      const generateURL = () => {
        const fetchSize = isPLP60ToggleEnabled ? PAGE_SIZE_60 : null;
        const additionalParam = queryBuilder(
          fetchSize, tabsInSizeFacet
        );
        const navPath = get(state, 'routing.locationBeforeTransitions.query.navpath', '');
        let navPathURL = `${NMConfig.API_PRODUCT_LIST}?${stringify({ ...defaultParams, ...additionalParam })}${sortByParams}${personalizedPromosParams}${priorityProdIdParam}&navPath=${navPath}`;
        const isInternational = get(state, 'toggles.INTERNATIONAL', false);
        if (isInternational) {
          const currencyCode = get(state, 'locale.currencyCode', 'USD');
          const countryCode = get(state, 'locale.countryCode', 'us');
          if (currencyCode !== 'USD') {
            let currencyQuery = `?currency=${currencyCode}&countryCode=${countryCode}`;
            if (navPathURL.indexOf('?') > -1 || navPathURL.indexOf('&') > -1) {
              currencyQuery = `&currency=${currencyCode}&countryCode=${countryCode}`;
            }
            navPathURL = `${navPathURL}${currencyQuery}`;
          }
        }
        return navPathURL;
      };
      return generateURL();
    }

    function shouldShowNoFavoriteMessage(products) {
      return state.productListPage.selectedSortBy !== MY_FAVORITES && sortBy === MY_FAVORITES
        && (isEmpty(page) || page === '1')
        && (products.length === 0 || ((state.page.pageId === 'PAGE_ID_PLP')
          ? !products[0].fav
          : !products[0].isFavorite));
    }

    function isSortOptionChanged(sortBy) {
      const previousSortOption = get(state, 'productListPage.products.selectedSortOption');
      return !isEmpty(sortBy)
        && !isEmpty(previousSortOption)
        && previousSortOption !== sortBy;
    }

    function isFilterOptionsChanged(selectedFilters) {
      const previousFilterOptions = get(state, 'productListPage.selectedFilterOptions');
      if (previousFilterOptions === "No filter options selected" && !isEmpty(selectedFilters)) {
        const {...duplicateSelectedFilter} = selectedFilters;
        duplicateSelectedFilter['In Store'] = duplicateSelectedFilter['In Store']?.filter((element,index) => (index > 0 && (element !== '' && element !== null)));
        return getSelectedFilterCount(duplicateSelectedFilter) > 0
          && !isEmpty(previousFilterOptions)
          && !isEqual(previousFilterOptions, selectedFilters);
      } else {
        return !isEmpty(selectedFilters)
          && isObject(previousFilterOptions)
          && !isEmpty(previousFilterOptions)
          && !isEqual(previousFilterOptions, selectedFilters);
      }
    }

    function shouldShowInStoreFilterMessage(products) {
      return (isEmpty(products)) && !isEmpty(filterOptions) && includes(filterOptions, IN_STORE)
        && !isEmptyInStoreFilterValue(JSON.parse(filterOptions)[IN_STORE]);
    }
    function showInStoreFilterMessageSeo(products, selectedFilters) {
      return (isEmpty(products)) && selectedFilters && selectedFilters?.[IN_STORE]
        && !isEmptyInStoreFilterValue(selectedFilters[IN_STORE]);
    }
    function shouldShowFilterMessage(products) {
      return isEmpty(products) && !isEmpty(filterOptions);
    }

    function getPlpType(displayAsGroupsFlag) {
      return displayAsGroupsFlag ? 'group' : 'non-group';
    }
    function getOptAnalyticsFromState(state){
      const abTestsOptArr = [];
      if (state.abTestsOpt) {
        forEach(state.abTestsOpt, (item) => {
          if (item.analytics) {
              abTestsOptArr.push(item.analytics);
          }
        });
      }
      return abTestsOptArr;
    }

    function setFavDesignerTagAnalytics(selectedFiltersUtag, state) {
      let clonedDesigner = selectedFiltersUtag['Designer'];
      if (clonedDesigner && clonedDesigner?.length > 0 && state.facetedLeftNav?.selectedFavoriteDesigners
        && state.facetedLeftNav?.selectedFavoriteDesignersList?.length > 0) {
        clonedDesigner = clonedDesigner?.map(item => formatDesignerName(item));
        let selectedFavoriteDesignersList = state.facetedLeftNav?.selectedFavoriteDesignersList?.
          filter((designer) => {
            if (clonedDesigner.indexOf(formatDesignerName(designer)) > -1) {
              return selectedFiltersUtag['Designer'][clonedDesigner?.indexOf(formatDesignerName(designer))];
            }
          });
        if (selectedFavoriteDesignersList?.length > 0) {
          selectedFiltersUtag[FAVORITE_DESIGNERS] = selectedFavoriteDesignersList;
        }
        selectedFavoriteDesignersList?.forEach((designer) => {
          let index = clonedDesigner?.indexOf(formatDesignerName(designer));
          if (index > -1) {
            selectedFiltersUtag['Designer']?.splice(index, 1);
            clonedDesigner?.splice(index, 1);
          }
        });
        return selectedFiltersUtag;
      } else {
        return selectedFiltersUtag;
      }
    }
    
    return new Promise((resolve, reject) => {
      const selectedFilters = isEmpty(filterOptions) ? {} : JSON.parse(filterOptions);
      const { session } = getState();
      const { promos } = JSON.parse(get(session, 'dt_personalize_data', '{}'));
      const crpUrl = getProductListApiURL(
        categoryId, parentCategoryId, siloCategoryId, page, sortBy, filterOptions, promos,
        priorityProdId
      );
      requestApi.get(crpUrl, { headers })
        .then((successResponse) => {
          if (
            successResponse.data.templateType.match(/P/, 'i')
              === null
            && location
          ) {
            window.location.reload();
            return resolve();
          }

          const { data: { products } } = successResponse;
          const rejectionFlag = shouldShowFilterMessage(products);
          const { ecmContent } = {};
          let inStoreRejectionFlag = shouldShowInStoreFilterMessage(successResponse.data.products);
          let selectedFiltersUtag = selectedFilters;
          if (seoToggle) {
            const locationQueryString = location?.search?.split('?')[1];
            selectedFiltersUtag = seoSelectedFilter(successResponse.data, locationQueryString);
            inStoreRejectionFlag = showInStoreFilterMessageSeo(products, selectedFiltersUtag);
          }
          if (inStoreRejectionFlag) {
            return Promise.reject({
              type: NO_RESULTS_FOR_FACET_FILTER,
              message: INSTORE_FILTER_EMPTY_PRODUCT_LIST_ERROR_MESSAGE,
            });
          }

          if (rejectionFlag) {
            return Promise.reject({
              type: NO_RESULTS_FOR_FILTER,
              message: PRODUCT_LIST_FILTER_OPTIONS_EMPTY_MESSAGE,
            });
          }
          const productsToDispatch = successResponse.data;

          const driveToGroups = false;
          const boutiqueChild = false;

          const { lsc } = getParams(window?.location);
          const updatedProducts = swatchesMapper(productsToDispatch?.products, lsc);


          dispatch({
            type: RESOLVED_PRODUCT_LIST,
            payload: {
              ...productsToDispatch,
              ...(!isEmpty(updatedProducts) && { products: updatedProducts }),
              page,
              driveToGroups,
              boutiqueChild,
            },
            ftrTgls: {
              useCloudImgs: true,
              dprToggle: get(state, 'toggles.CLOUDINARY_DPR', false),
            },
          });
          const navivationFalg = successResponse.data.hybridLeftNavigation;
          if (getState().device && getState().device.isMobilePhone) {
            const navigationData = successResponse.data.hybridLeftNavigation;
            if (navivationFalg) {
              dispatch({
                type: NavTypes.RESOLVED_LEFTNAV_CONTENT,
                payload: navigationData,
              });
            }
          }
          const breadCrumbsFlag = successResponse.data.breadcrumbs;
          const breadCrumbData = get(successResponse, 'data.breadcrumbs.breadcrumbs', []);
          if (breadCrumbsFlag) {
            const breadcrumbPath = get(
              state,
              'routing.locationBeforeTransitions.query.navpath',
              ''
            );
            dispatch({
              type: 'RESOLVED_BREADCRUMB_CONTENT',
              payload: { breadcrumbs: breadCrumbData, breadcrumbPath },
            });
            dispatch({
              type: 'PLP_SPA_PATH_UPDATE',
              breadcrumbPath,
            });
            const templateType = successResponse.data.templateType;
            let pageName = formatAnalyticsPageName(
              templateType,
              breadCrumbData
            );
            const breadcrumbsUtagData = breadcrumbsUtag(breadCrumbData);
            let { breadCrumb } = breadcrumbsUtagData;
            const { catId } = breadcrumbsUtagData;
            const brand = state.brand_name?.env || 'NM';
            if (brand !== 'HC' && reactCookie.load('dt_gender')) {
              const selectedGender = getSelectedGender() === 'W' ? 'Women' : 'Men';
              breadCrumb = [selectedGender, ...breadCrumb];
              pageName = `${selectedGender}:${pageName}`;
            }
            if (window.utag_data_dt) {
              window.utag_data_dt.cat_id = catId;
              window.utag_data_dt.bread_crumb = breadCrumb;
              window.utag_data_dt.page_name = pageName;
            }
          }

          dispatch(hideSpinner());
          let selectedFilterObject = (isEmpty(filterOptions) ? 'No filter options selected' : JSON.parse(filterOptions));
          if(seoToggle){
            selectedFilterObject = isEmpty(selectedFiltersUtag)? 'No filter options selected' : selectedFiltersUtag;
          }
          dispatch({
            type: types.SET_FILTER_OPTIONS,
            selectedFilterOptions: selectedFilterObject,
          });
          let canonicalUrl = '';
          let sortByOption = '';

          if (successResponse.data.seo.canonicalUrl) {
            canonicalUrl = successResponse.data.seo.canonicalUrl;
          }
          sortByOption = successResponse.data.selectedSortOption;
          const seoFirstParam = state?.facetedLeftNav?.firstParam;
          const seoSecondParam = state?.facetedLeftNav?.secondParam;
          if (isEmpty(seoFirstParam) && isEmpty(seoSecondParam)) {
            
            if (page && page > 1) {
              canonicalUrl = canonicalUrl + `?page=${page}`;
            }
            
            dispatch({ type: SET_CANONICAL_URL, canonicalUrl });
          } else if (!isEmpty(seoFirstParam) || !isEmpty(seoSecondParam)) {

            const pageCanonical = getState().page?.canonical;
             
            if (pageCanonical) {

              canonicalUrl = pageCanonical.replace(/&page=\d+/g, '');

              if (page > 1) {
                canonicalUrl += `&page=${page}`;
              } 
              
              dispatch({ type: SET_CANONICAL_URL, canonicalUrl });
            }

          }

          dispatch({ type: types.SET_SORT_BY, sortBy: sortByOption });
          dispatch({ type: VALID_CATEGORY });

          let cspToggleValue = false;
          const brand = state?.brand_name?.env;
          if (getState().device && getState().device.isMobilePhone) {
            const isCspEnabled = brand !== 'HC' && !isEmpty(selectedFilters) && selectedFilters.hasOwnProperty('In Store') ? !isEmpty(selectedFilters['In Store'][1]) : false;
            !IS_CLIENT && !isCspEnabled && reactCookie.remove('cCspTogglePath', { path: '/' });
            if (reactCookie.load('cCspTogglePath') === 'facet') {
              cspToggleValue = false;
            }
            if (reactCookie.load('cCspTogglePath') === 'cspToggle') {
              cspToggleValue = true;
            }
          }

          setFavDesignerTagAnalytics(selectedFiltersUtag, state);
          dispatch({
            type: SET_PRODUCT_LIST_DATA_TO_UTAG,
            payload: {
              total: successResponse.data.total,
              templateType: successResponse.data.templateType,
              category: currentCategory,
              cmosCatalog: map(successResponse.data.products, 'cmosCatalog'),
              cmosItem: map(successResponse.data.products, 'cmosItem'),
              sortBy: successResponse.data.selectedSortOption,
              page,
              facetOptions: getUtagFiltersData(selectedFiltersUtag, state),
              previousViewFacetSubmit: isFilterOptionsChanged(selectedFiltersUtag),
              previousViewSortSubmit: isSortOptionChanged(sortBy),
              facetFlag: true,
              facetEligible: true,
              plpType: getPlpType(successResponse.data.displayAsGroups),
              pcsEnabled: '',
              ...(IS_CLIENT && getState().device && getState().device.isMobilePhone
                && { csp_toggle: cspToggleValue }),
              product_id: map(successResponse.data.products, 'id'),
              ...updateGrsUtagData(successResponse.data),
            },
          });

          const shouldShowFavMessage = shouldShowNoFavoriteMessage(successResponse.data.products);
          if (shouldShowFavMessage) {
            dispatch(openModal({
              type: 'ModalWithoutHeader',
              message: FAVORITES_NOT_AVAILABLE_MESSAGE,
            }));
          }

          const contentId = 'promotiles';
          if (!isEmpty(ecmContent)) {
            dispatch({
              type: `RESOLVED_ECM${contentId}`,
              payload: ecmContent,
              contentId,
              headers,
            });
          } else if (!isEmpty(getState().ecm)) {
            const tiles = Object.keys(getState().ecm.ecmContent).filter((key) => { return key.indexOf('PromoTile') !== -1; });
            tiles.forEach((tile) => {
              dispatch({
                type: `RESOLVED_ECM${tile}`,
                payload: {},
                contentId: tile,
                headers,
              });
            });
          }
          if (isEmpty(selectedFilters) && !seoToggle) {
            dispatch({
              type: 'CLEAR_ALL_FILTER_OPTION',
              payload: {
                search: state?.page?.location?.search,
              },
            });
          }
          if (seoToggle && state?.device?.isMobilePhone) {
            let totalFilterCount = 0;
            for (const [key, value] of Object.entries(selectedFiltersUtag)) {
              if (key === IN_STORE) {
                totalFilterCount += value.filter((element, index) => (index > 0 && element !== '' && element !== null)).length;
              } else {
                totalFilterCount += value.length;
              }
            }
            totalFilterCount === 0 && dispatch({
              type: 'CLEAR_ALL_FILTER_OPTION',
              payload: {
                search: state?.page?.location?.search,
              },
            });
          }

          const productListing = get(successResponse.data, 'categoryTemplate.templateAttributes.dynamicContent.productListing', {});
          const applicableFilters = get(productListing, 'applicableFilters', []);
          const seoFacets = get(successResponse, 'data.seoFacets', []);
          if (!isEmpty(seoFacets)) {
            const lowerCaseFacets = seoFacets.map(
              (facet) => (facet.toLowerCase())
            );
            dispatch({
              type: SET_SEO_FACETS_ORDER,
              payload: lowerCaseFacets,
            });
            const firstKeyFormatted = replaceSpaceWithHyphenAndEncode(lowerCaseFacets[0]);
            const firstFilter = applicableFilters.find((filter) => filter.displayText === seoFacets[0]);
            const firstValue = { displayText: seoFacets[0], filterKey: firstFilter?.filterKey };
            const secondKeyFormatted = replaceSpaceWithHyphenAndEncode(lowerCaseFacets[1]);
            const secondFilter = applicableFilters.find((filter) => filter.displayText === seoFacets[1]);
            const secondValue = { displayText: seoFacets[1], filterKey: secondFilter?.filterKey };
            const seoFacetsMap = [{ [firstKeyFormatted]: firstValue }, { [secondKeyFormatted]: secondValue }];
            dispatch({
              type: SET_SEO_FACETS_MAP,
              payload: seoFacetsMap,
            });
          }
          return resolve();
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            dispatch({ type: INVALID_CATEGORY });
          }
          dispatch(hideSpinner());
          if (err.response && err.response.status === 424) {
            logger.error(`API timed out while waiting to search for products for categoryId: ${categoryId}, HTTP Status: ${err.response.status}`);
          }
          reject(err);
        });
    });
  };
}

export function getProductListMetaData(productIds, customHeaders) {
  return (dispatch, getState) => {
    const state = getState();
    const { session } = state;
    const requestApi = httpWithLogging(
      state,
      get(state, 'apiTimeouts.PRODUCT_LIST_METADATA_API_TIMEOUT', 20000)
    );
    const combinedHeaders = {
      ...customHeaders,
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        DYN_USER_CONFIRM: get(session, 'DYN_USER_CONFIRM', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };
    dispatch({ type: LOADING_PRODUCT_LIST_METADATA });
    return new Promise((resolve) => {
      requestApi
        .post(`${NMConfig.API_PRODUCT_LIST_METADATA}`, productIds, { headers: combinedHeaders })
        .then((successResponse) => {
          dispatch({
            type: RESOLVED_PRODUCT_LIST_METADATA,
            payload: successResponse.data,
          });
          resolve();
        })
        .catch(() => { });
    });
  };
}

export function getCategoryNoResultsContent() {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);

    return requestApi.get(NMConfig.API_REFRESHABLE_CONTENT, {
      params: { refreshablePath: '/category/search/endeca/r_no_results.html' },
    }).then((response) => {
      dispatch({ type: RESOLVED_CATEGORY_NO_RESULTS_CONTENT, payload: response.data });
    }).catch(() => { dispatch({ type: REJECTED_CATEGORY_NO_RESULTS_CONTENT }); });
  };
}

export function addFavorite(
  productId, cmosCatalogId, cmosItem, isFavoritesPage, isDisplayAsGroups
) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    const query = stringify({
      productId, cmosCatalogId, cmosItem, isFavoritesPage,
    });
    return requestApi.post(`${NMConfig.API_ADD_FAVORITE}?${query}`)
      .then((successResponse) => {
        if (state.page.pageId === 'PAGE_ID_PLP') {
          dispatch({
            type: RESOLVED_FAVORITE_FLATTENED,
            data: successResponse.data,
          });
        } else {
          dispatch({
            type: RESOLVED_FAVORITE,
            data: successResponse.data,
          });
        }
        setRecentlySearchedAnalytics(false);
        dispatch({
          type: SET_FAVORITE_UTAG_DATA,
          payload: {
            cmosCatalogId,
            utagData: successResponse.data.utagData,
            displayAsGroups: isDisplayAsGroups,
          },
        });
        reactCookie.save('SPCR', 0, { path: '/' });
      })
      .catch(() => { });
  };
}
export function removeFavorite(
  productId, cmosCatalogId, cmosItem, isFavoritesPage, isDisplayAsGroups
) {
  return (dispatch, getState) => {
    const state = getState();
    const requestApi = httpWithLogging(state);
    const query = stringify({
      productId, cmosCatalogId, cmosItem, isFavoritesPage,
    });
    return requestApi.post(`${NMConfig.API_REMOVE_FAVORITE}?${query}`)
      .then((successResponse) => {
        if (state.page.pageId === 'PAGE_ID_PLP') {
          dispatch({
            type: RESOLVED_FAVORITE_FLATTENED,
            data: successResponse.data,
          });
        } else {
          dispatch({
            type: RESOLVED_FAVORITE,
            data: successResponse.data,
          });
        }
        dispatch({
          type: SET_FAVORITE_UTAG_DATA,
          payload: {
            cmosCatalogId,
            utagData: successResponse.data.utagData,
            displayAsGroups: isDisplayAsGroups,
          },
        });
        reactCookie.save('SPCR', 0, { path: '/' });
      })
      .catch(() => { });
  };
}

export function showQLModalWindow(productId, productUrl, navPath, isDisplayAsGroups) {
  const data = {
    productId,
    productUrl,
    navPath,
  };

  return (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT });
    const state = getState();
    const { session } = state;
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        TLTSID: get(session, 'TLTSID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const { promos } = JSON.parse(get(session, 'dt_personalize_data', '{}'));

    let requestURI = promos && promos.length
      ? `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}?personalizedPromos=${promos}`
      : `${NMConfig.API_PRODUCT_DETAIL_PDP}/${productId}`;
    const requestApi = httpWithLogging(state);
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      const countryCode = get(state, 'locale.countryCode', 'US');
      if (currencyCode !== 'USD') {
        let currencyQuery = `?currency=${currencyCode}&country=${countryCode}`;
        if (requestURI.indexOf('?') > -1 || requestURI.indexOf('&') > -1) {
          currencyQuery = `&currency=${currencyCode}&country=${countryCode}`;
        }
        requestURI = `${requestURI}${currencyQuery}`;
      }
    }
    return requestApi.get(requestURI, { headers })
      .then((successResponse) => {
        dispatch({
          type: RESOLVED_QUICK_LOOK_PRODUCT,
          payload: {
            ...successResponse.data,
            displayAsGroups: isDisplayAsGroups,
          },
          ftrTgls: {
            useCloudImgs: true,
            dprToggle: get(state, 'toggles.CLOUDINARY_DPR', false),
          },
        });
        const product = successResponse.data || {};
        if (product.isGroup) {
          dispatch({ type: 'RESOLVED_GROUP', payload: successResponse.data });
        }
        dispatch(openModal({
          type: 'QLProductSummary',
          props: data,
          className: 'ql-product-summary',
        }));
      })
      .catch(() => {
        dispatch(openModal({ type: 'ProductListErrorModal' }));
      });
  };
}

export function sourceQuickLook(isEnabled) {
  if (isEnabled) setRecentlySearchedAnalytics(!isEnabled);
  return (dispatch) => dispatch({ type: SOURCE_QUICK_LOOK, payload: isEnabled });
}

export function resetQLProduct() {
  return (dispatch) => dispatch({ type: RESET_QUICK_LOOK_PRODUCT });
}

export function updateSwatchesValue(swatchIndex, productId) {
  return (dispatch) => dispatch({
    type: UPDATE_SWATCH_VALUE,
    payload: {
      swatchIndex,
      productId,
    },
  });
}

export function initializeSwatchValues() {
  return (dispatch) => dispatch({ type: INIT_SWATCH_VALUES });
}

export function updateCatId(catId) {
  return { type: UPDATE_CAT_ID, payload: { catId } };
}

export const updateAltImageUtag = () => {
  return (dispatch) => dispatch({ type: SET_ALT_IMAGE_UTAG });
}

export const updateInStoreFacetAvailability = (hasInStoreFacet) => {
  return (dispatch) => dispatch({
    type: IN_STORE_FACET_AVAILABLE,
    payload: {
      hasInStoreFacet
    },
  });
}

export const updateMvpFilterUtag = () => {
  return (dispatch) => dispatch({ type: SET_MVP_FILTER_UTAG });
}

export const removeMvpFilterUtag = () => {
  return (dispatch) => dispatch({ type: REMOVE_MVP_FILTER_UTAG });
}

export function matchFristAvalibleShot (shots, order) {
  if(shots == null) return null;
  for (let index = 0; index < order.length; index++) {
    if(shots[order[index]])
      return shots[order[index]];
  }
  return null;
}

function invokeAttributionAPI(customerId, openedAt, cvId, loggedIn, headers) {
  const body = {
    customer_id: customerId,
    opened_at: openedAt,
    cv_id: cvId,
    loggedIn,
  };
  axios.post(`${NMConfig.API_ATTRIBUTION}`, body, { headers });
}

export function attributionClientVisit(cvId, retryAttempt  = false) {
  const openedAt = new Date().getTime();
  const headers = {
    'Content-Type': 'application/json',
  };
  Auth.currentSession().then((user) => {
    const jwtToken = user.getIdToken().getJwtToken();
    const ucaId = user.getIdToken().payload?.sub;
    headers['Authorization'] = `Bearer ${jwtToken}`,
    invokeAttributionAPI(ucaId, openedAt, cvId, true, headers);
  }).catch((err) => {
    logger.error(err);
    const { Sub: guestId } = getGuestTokens(['Sub']);
    if (retryAttempt)  {
      logger.warn('Retry attempted', { guestId });
    }
    if (guestId && guestId.length > 0) {
      invokeAttributionAPI(guestId, openedAt, cvId, false, headers);
    } else if (!retryAttempt) {
      logger.warn('GuestId is not found, will retry after sometime.');
      setTimeout(() => attributionClientVisit(cvId, true), 3000);
    }
  });
}

export const getProductRecommendations = (catId, boutiqueRecsToggle=false, boutiquePage=false, categoryInfo={}, productRecToggle) => {
  return async (dispatch) => {

    let ucaId = false;
    
    try {
      const user = await Auth.currentSession();
      ucaId = user.getIdToken().payload?.sub;
    } catch (err) {
      const { Sub } = getGuestTokens(['Sub']);
      ucaId = Sub || false;
    }

    const queryParams = {
      type: "MasterStyle",
      sourceCatId: catId,
      placement: boutiqueRecsToggle ? "PLP_BOUTIQUE_CAROUSEL" : "PLP_BROWSE_CAROUSEL",
      channel: "NMO", 
      gender: "Women",
      ucaId,
    };

    if(boutiquePage && productRecToggle) {
      queryParams['placement'] = "PLP_BOUTIQUE_CAROUSEL" ;
    }
    
    if(boutiquePage){
      queryParams['brand']=categoryInfo?.brand?.name||null;
      queryParams['brandId']=categoryInfo?.brand?.id||null;

    }
    queryParams['groups']=Object.values(categoryInfo.groups).map(item => item.analytics).join(',')
    
    try {
    
      const url = `${NMConfig.API_PRODUCT_RECS}?${stringify(queryParams)}`;
      const { data } = await axios.get(url);
  
      dispatch({
        type: 'SET_PRODUCT_RECS',
        payload: data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: 'SET_PRODUCT_RECS',
        payload: [],
      });
      return [];
    }
  };
};